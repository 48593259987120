<template>
  <div class="footer">
    <div class="qr-code fl"></div>
    <ul class="footer-ul footer-ul1">
      <li v-for="item in message" :key="item.id" :style="{cursor:(item.linkUrl==null?'default':'pointer')}" @click="goLink(item.linkUrl)">{{ item.textarec }}</li>
      <li>
        <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=12010402000789" style="display:inline-block;text-decoration:none;height:20px;line-height:20px;">
          <img src="../images/jbh.jpg" style="float:left;" />
          <p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#fff;">津公网安备 12010402000789号</p>
        </a>
      </li>
    </ul>
    <ul class="footer-ul footer-ul2">
      <li v-for="item in list" :key="item.id">
        <a :href="item.links" target="_blank">{{ item.title }}</a>
      </li>
    </ul>
  </div>
</template>

<script>
import { webInfo, links } from "../api/api";
export default {
  name: "footeri",
  data() {
    return {
      list: [],
      message: []
    };
  },
  mounted() {
    webInfo().then(res => {
      this.message = res.result.data;
    });
    links().then(res => {
      this.list = res.result.data;
    });
  },
  methods: {
    goLink(url) {
      url == null ? "" : window.open(url);
    }
  }
};
</script>

<style scoped lang="less">
.footer {
  width: 1200px;
  margin: 0 auto;
  padding: 64px 0 0;
  font-size: 14px;
  .qr-code {
    width: 135px;
    height: 135px;
    margin: 26px 0 0 160px;
    background: url("../images/QRcode.jpg") no-repeat;
    background-size: cover;
  }
  .footer-ul {
    float: left;
    li {
      color: #fff;
    }
    a {
      color: #fff;
    }
  }
  .footer-ul1 {
    padding: 30px 50px 30px 20px;
    border-right: 1px solid #fff;
    width: 400px;
    min-height: 100px;
    li {
      margin: 10px 0;
    }
  }
  .footer-ul2 {
    width: 320px;
    margin: 25px 50px 0;
    li {
      margin: 14px 30px;
      float: left;
    }
  }
}
</style>
