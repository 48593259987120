import Vue from "vue";
import VueRouter from "vue-router";
import Main from "../views/main.vue";
Vue.use(VueRouter);

// const routes = [
//
//   {
//     path: "/",
//     name: "home",
//     component: Home
//   },
//   {
//     path: "/about",
//     name: "about",
//     // route level code-splitting
//     // this generates a separate chunk (about.[hash].js) for this route
//     // which is lazy-loaded when the route is visited.
//     component: () =>
//       import(/* webpackChunkName: "about" */ "../views/About.vue")
//   }
// ];
const routes = [
  {
    path: "/",
    name: "Main",
    component: Main,
    children: [
      {
        path: "/",
        name: "home",
        component: () => import("@/views/home.vue")
      },
      {
        path: "/list/:type/:num",
        name: "list",
        component: () => import("@/views/list.vue")
      },
      {
        path: "/listDetails/:num",
        name: "listDetails",
        component: () => import("@/views/list-details.vue")
      },
      {
        path: "/detail/:id/:type",
        name: "detail",
        component: () => import("@/views/details.vue")
      },
      {
        path: "/search/:con",
        name: "search",
        component: () => import("@/views/search.vue")
      }
    ]
  }
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes
});

export default router;
