const app = {
  state: {
    menuListCurrent: localStorage.menuListCurrent
      ? JSON.parse(localStorage.menuListCurrent)
      : "0"
  },
  mutations: {
    changeMenuCurent(state, theme) {
      localStorage.setItem("menuListCurrent", theme);
    }
  }
};
export default app;
