<template>
  <div>
    <div class="header">
      <div class="main">
        <img class="header-logo" src="../images/logo.png" />
        <img class="header-name" src="../images/name.png" />
        <div class="search">
          <input type="text" placeholder="请输入关键字搜索" v-model="searchCon" />
          <button @click="goSearch()">搜索</button>
        </div>
      </div>
    </div>
    <div class="nav">
      <Nav :nav-list="navList" :nav-actives="navActives" />
      <div class="banner"></div>
    </div>
    <div class="main">
      <router-view />
    </div>
    <div class="foot">
      <Footeri />
    </div>
  </div>
</template>

<script>
import Nav from "../components/nav";
import Footeri from "../components/footer";
import { menudata } from "../navJSON/nav";

export default {
  name: "Main",
  components: {
    Nav,
    Footeri
  },
  data () {
    return {
      navList: [],
      navActives: "",
      swiper: [],
      message: [],
      searchCon: ""
    };
  },
  methods: {
    init () {
      this.navList = menudata;
    },
    getNavActive () {
      menudata.forEach((item, index) => {
        if (item.link == this.$route.path) {
          return index;
        }
      });
    },
    goSearch () {
      console.log(this.searchCon);
      this.$router.push({
        name: "search",
        params: { con: this.searchCon }
      });
    }
  },
  mounted () {
    this.init();
  },
  created () {
    menudata.forEach((item, index) => {
      if (this.$route.name == "home") {
        this.navActives = 0;
      } else if (this.$route.name == "listDetails") {
        this.navActives = 5;
      } else {
        if (item.name == this.$route.params.type) {
          this.navActives = index;
        }
      }
    });
  },
  watch: {
    $route () {
      menudata.forEach((item, index) => {
        if (this.$route.name == "home") {
          this.navActives = 0;
        } else if (this.$route.name == "listDetails") {
          this.navActives = 5;
        } else {
          if (item.name == this.$route.params.type) {
            this.navActives = index;
          }
        }
      });
    }
  }
};
</script>
<style scoped lang="less">
.banner {
  background: url("../images/banner.png") no-repeat;
  background-size: cover;
  height: 5.27rem;
}

.main {
  width: 1200px;
  margin: auto;
  position: relative;
}

.foot {
  margin-top: 100px;
  width: 100%;
  height: 3.66rem;
  background: url("../images/footer.png") no-repeat;
  background-size: cover;
}
.search {
  float: right;
  margin-top: 60px;
  input {
    width: 240px;
    height: 32px;
    padding: 0 20px;
    border-radius: 16px 0 0 16px;
    border: 0;
    vertical-align: middle;
    outline: none;
  }
  button {
    width: 80px;
    height: 32px;
    border-radius: 0 16px 16px 0;
    border: 0;
    background-color: #eab638;
    color: #fff;
    vertical-align: middle;
    cursor: pointer;
    outline: none;
  }
}
</style>
