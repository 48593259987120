export const menudata = [
  {
    id: "d61163fffe574251a9877d974480cdab",
    name: "首页",
    link: "home"
  },
  {
    id: "87540ff435b64edea5550ddd8b9a8523",
    name: "足球在天津",
    link: "list"
  },
  {
    id: "3e1d48c30973496682de765eec1174ad",
    name: "新闻与公告",
    link: "list",
    children: [
      {
        name: '全部',
        num: 0
      },
      {
        name: '中超新闻',
        num: 1
      },
      {
        name: '中乙新闻',
        num: 2
      },
      {
        name: '中甲新闻',
        num: 3
      },
      {
        name: '校园足球',
        num: 4
      },
      {
        name: '足协新闻',
        num: 5
      },
      {
        name: '足协公告',
        num: 6
      },
      {
        name: '女足新闻',
        num: 7
      },
      {
        name: '老甲A新闻',
        num: 8
      },
      {
        name: '成绩公告',
        num: 9
      },
      {
        name: '米盖尔杯',
        num: 10
      },
    ]
  },
  {
    id: "ddc313e2eee54e9cb39e3127d9faaaf2",
    name: "赛事与活动",
    link: "list"
  },
  {
    id: "10729d89915c4f0b9c63c89622d74b78",
    name: "青少年培训",
    link: "list"
  },
  {
    id: "e749bac4a54f477788496cad0321b818",
    name: "协会介绍",
    link: "listDetails"
  }
];
