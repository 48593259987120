<template>
  <div class="nav-con">
    <ul class="cl">
      <li
        v-for="(item, index) in navList"
        :key="item.id"
        @click="changNav(item.name, item.link, index)"
        :class="[navActives == index ? 'active' : '']"
      >
        {{ item.name }}
        <span></span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "nav-i",
  props: { navList: { type: Array }, navActives: [Number, String] },
  data() {
    return {
      list: this.navList,
      navActive: this.navActives
    };
  },
  methods: {
    init() {},
    changNav(name, link, index) {
      this.navActive = index;
      if (index == 0) {
        this.$router.push({
          name: link
        });
      } else if (index == 5) {
        this.$router.push({
          name: link,
          params:{num:0}
        });
      } else {
        this.$router.push({
          name: link,
          params: { type: name, num: 0 }
        });
      }
    }
  },
  mounted() {
    this.init();
  }
};
</script>

<style scoped lang="less">
.nav-con {
  background-color: #02295d;
  border-bottom: 4px solid #eab638;
  ul {
    width: 1200px;
    margin: auto;
    li {
      width: 200px;
      padding: 14px 0;
      text-align: center;
      float: left;
      color: #fff;
      cursor: pointer;
    }
    li:hover {
      background-color: #eab638;
    }
    li.active {
      background-color: #eab638;
    }
  }
}
</style>
