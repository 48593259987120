import axios from 'axios'
// let query = require('querystring')
export let base = 'http://www.thetjfa.com/newfootball/'
// export let base = "http://192.168.1.24:8084/newfootball/";
// export let base = "http://football.tiptimes.com/newfootball/";
export let baseImg = 'http://www.thetjfa.com/Filefootball/'
// http://192.168.1.58:8080/football
//获取一级栏目
// export const getNav = params => {
//   return axios.post(base + '/api/topicList', query.stringify(params))
// }

// //获取二级栏目
// export const getNavSec = params => {
//   return axios.post(base + '/api/moudles`, query.stringify(params))
// }

// //获取二级栏目列表
// export const getList = params => {
//   return axios.post(base + '/api/articleList`, query.stringify(params))
// }

// //获取详情
// export const getDetails = params => {
//   return axios.post(base + '/api/articleDetail`, query.stringify(params))
// }

// //获取首页推荐
// export const getRecommend = params => {
//   return axios.post(base + '/api/activityByRecommend`, query.stringify(params))
// }

// //获取首页轮播
// export const getRecycle = params => {
//   return axios.post(base + '/api/activityByRecycle`, query.stringify(params))
// }

// //获取全部
// export const allNews = params => {
//   return axios.post(base + '/api/allNews`, query.stringify(params))
// }

// //搜索
// export const searchAll = params => {
//   return axios.post(`${base}/api/searchAll`, query.stringify(params));
// };

//获取二级栏目列表（新）
export const getListT = params => {
  return axios.post(base + 'api/news?' + params)
}
//搜索search
export const searchAll = params => {
  return axios.post(base + 'api/search?' + params)
}
//获取详情
export const detail = params => {
  return axios.post(base + 'api/detail?' + params)
}
//获取详情
export const score = params => {
  return axios.post(base + 'api/score?' + params)
}

//首页推荐
export const mainNews = params => {
  return axios.post(base + 'api/mainNews?' + params)
}
//合作伙伴
export const partner = () => {
  return axios.post(base + 'api/partner')
}
//网站简介
export const webInfo = () => {
  return axios.post(base + 'api/webInfo')
}
//网站简介
export const links = () => {
  return axios.post(base + 'api/links')
}
//列表详情
export const present = () => {
  return axios.post(base + 'api/present')
}
